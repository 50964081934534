let linkedinId = "islamsamiul";
let githubID = "cs-isamiul";
let email = "cs.isamiul@gmail.com"

let resumeData = {
    "imagebaseurl":"https://rbhatia46.github.io/",
    "name": "Samiul Islam",
    "role": "Computer Science student",
    "linkedinId":`${linkedinId}`,
    "githubID": `${githubID}`,
    "email": `${email}`,
    "roleDescription": " I like learning about new technologies and techniques. In particular, I like tinkering around with a lot of different things.",
    "socialLinks":[
        {
          "name":"linkedin",
          "url":`https://www.linkedin.com/in/${linkedinId}/`,
          "className":"fa fa-linkedin"
        },
        {
          "name":"github",
          "url":`http://github.com/${githubID}`,
          "className":"fa fa-github"
        }
      ],
    "aboutme":"I am a graduating senior at The Ohio State University pursuing a B.S in Computer Science. I have experience in full stack development and have a variety of different technologies under my belt.",
    "address":"Dublin, Ohio",
    "website":"https://www.isamiul.com/",
    "education":[
      {
        "UniversityName":"The Ohio State University",
        "specialization":"Software Engineering",
        "MonthOfPassing":"May",
        "YearOfPassing":"2023",
        "Achievements":"Dean's List 2020, HackOH/IO X Honda Challenge 3rd Place"
      }
    ],
    "work":[
      {
        "CompanyName":"Some Company",
        "specialization":"Some specialization",
        "MonthOfLeaving":"Jan",
        "YearOfLeaving":"2018",
        "Achievements":"Some Achievements"
      },
      {
        "CompanyName":"Some Company",
        "specialization":"Some specialization",
        "MonthOfLeaving":"Jan",
        "YearOfLeaving":"2018",
        "Achievements":"Some Achievements"
      }
    ],
    "skillsDescription":"Your skills here",
    "skills":[
      {
        "skillname":"HTML5"
      },
      {
        "skillname":"CSS"
      },
      {
        "skillname":"Reactjs"
      }
    ],
    "portfolio":[
      {
        "name":"project1",
        "description":"mobileapp",
        "imgurl":"images/portfolio/phone.jpg"
      },
      {
        "name":"project2",
        "description":"mobileapp",
        "imgurl":"images/portfolio/project.jpg"
      },
      {
        "name":"project3",
        "description":"mobileapp",  
        "imgurl":"images/portfolio/project2.png"
      },
      {
        "name":"project4",
        "description":"mobileapp",
        "imgurl":"images/portfolio/phone.jpg"
      }
    ]
  }
  
  export default resumeData